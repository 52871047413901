import { api, post } from '~/helpers/api';
import { authHeaders } from '~/services/storage';
import { addFlash } from '~/modules/flashes';
import { setSignedIn, fetchCurrentUser } from '~/modules/currentUser';
import handleError from './_errorHandling';
import Config from '~/config';

// State
const INITIAL_STATE = {
  'access-token': null,
  client: null,
  uid: null,
};

// Actions
export const RESET_AUTH_HEADERS = 'piczel/auth/RESET_AUTH_HEADERS';
export const SET_AUTH_HEADERS = 'piczel/auth/SET_AUTH_HEADERS';

// Reducer
export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_AUTH_HEADERS:
      return INITIAL_STATE;
    case SET_AUTH_HEADERS:
      return {
        ...state,
        uid: action.uid,
        client: action.client,
        'access-token': action['access-token'],
      };
    default:
      return state;
  }
}

// Action creators
export function resetAuthHeaders() {
  return { type: RESET_AUTH_HEADERS };
}

/**
 * @param {AuthState} headers
 */
export function setAuthHeaders(headers) {
  return {
    type: SET_AUTH_HEADERS,
    client: headers.client,
    'access-token': headers['access-token'],
    uid: headers.uid,
  };
}

export function storeAuthHeaders(headers, user) {
  const data = {
    'access-token': headers.get('access-token'),
    uid: headers.get('uid'),
    client: headers.get('client'),
  };

  user.authHeaders = data;

  if (__CLIENT__) {
    // Store cookies if we're running in a browser
    authHeaders.serializeAndPersist(data);
  }
}

/**
 * Registers a user by his e-mail
 */

export function signUp(data) {
  return async function (dispatch) {
    const response = await post('', data);

    const user = await response.json();

    if (!user.errors) {
      dispatch(addFlash('success', `A confirmation email was sent to you at ${user.data.email}. Please click the link in it to confirm your registration.`));
    } else {
      user.errors.full_messages.forEach(e => dispatch(addFlash('error', e, false)));
    }

    return user;
  };
}

/**
 * If the email and passwords are correct, returns the user object + API access token
 * otherwise returns false
 *
 * @param {String} email
 * @param {String} password
 */
export function signIn(email, password) {
  return async function (dispatch, getState, fetch) {
    const response = await post('sign_in', {
      email,
      password,
    });

    const user = await response.json();

    const accessToken = response.headers.get('access-token');

    if (accessToken) {
      storeAuthHeaders(response.headers, user);
    }

    if (!user.errors) {
      dispatch(addFlash('success', `Welcome back ${user.username}!`));
      dispatch(setAuthHeaders(user.authHeaders));
      dispatch(setSignedIn(true));
    } else {
      user.errors.forEach(e => dispatch(addFlash('error', e)));
    }

    return user;
  };
}

export function validateToken(token, client, uid) {
  return async function (dispatch) {
    const response = await api(`/validate_token?access-token=${encodeURIComponent(token)}&client=${encodeURIComponent(client)}&uid=${encodeURIComponent(uid)}`);

    if (!response.ok) {
      console.warn('[API] validateToken failed');
    }

    const user = await response.json();

    if ('success' in user && user.success === false) {
      console.error(`Logged out user ${uid}, token=${token} client=${client}
      Server response: ${JSON.stringify(user)}
      `);
      return dispatch(addFlash('error', 'Your session has expired, you\'ve been logged out'));
    }

    dispatch(setSignedIn(true));
    return dispatch(fetchCurrentUser());
  };
}


/**
 * @returns {AsyncAction}
 */
export function signOut() {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/auth/sign_out`, {
      method: 'DELETE',
    });

    dispatch(setSignedIn(false));
    if (response.ok) {
      dispatch(resetAuthHeaders());
    }
  };
}

/**
 * @param {string} email The user email
 * @param {string} redirectURL The URL that the backend will redirect to after the user clicks the link
 *
 * @returns {AsyncAction}
 */
export function requestPasswordReset(email, redirectURL) {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/auth/password`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        redirect_url: redirectURL,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();

    if (response.ok) {
      dispatch(addFlash('success', json.message));
    } else {
      const { errors } = json;
      errors.forEach(errorMsg => dispatch(addFlash('error', errorMsg)));
    }
  };
}

/**
 * Makes a PUT request to /auth/password to change the password without requiring the current one
 *
 * @param {string} password
 * @param {string} passwordConfirmation
 * @returns {AsyncAction}
 */
export function resetPassword(password, passwordConfirmation) {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/auth/password`, {
      method: 'PUT',
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const json = await response.json();

    if (response.ok) {
      dispatch(addFlash('success', json.message));
    } else {
      const { errors: { full_messages: messages } } = json;
      messages.forEach(errorMsg => dispatch(addFlash('error', errorMsg)));
    }
  };
}

/**
 * Deletes the currentUser's account completely
 *
 * @param {string} currentPassword The current plaintext password, required by the backend in order to delete the account
 * @returns {AsyncAction}
 */
export function deleteAccount(currentPassword) {
  return function (dispatch, getState, fetch) {
    return fetch(`${Config.api}/users/me`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        current_password: currentPassword,
      }),
    }).then(res => handleError(dispatch, res));
  };
}
