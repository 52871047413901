/**
 * Avatar component
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { getCurrentUser } from '~/modules/currentUser';
import { loadAvatar } from '~/helpers/util';
import config from '~/config';

import memoFreezeImg, { frozenAvatars } from './freezing';

import styles from './index.scss';

const cx = classNames.bind(styles);

const DEFAULT_AVATAR = `${config.avatarsHost}/avatars/default`;


/**
 * Avatar compoonent, allows freezing gif avatars
 * currently it has no way to tell if an avatar is actually a gif or not so it just assumes it's a gif
 */
const Avatar = ({ user = null, userId = null, username = null, controlled = false, hovering = false, className = '', size = undefined, previewImg = null, ...props }) => {
  const currentUser = useSelector(getCurrentUser);
  const isStatic = user?.avatar?.url && !user.avatar.url.endsWith('.gif');

  const src = (() => {
    const isMyAvatar = currentUser && currentUser.username === username;

    if (previewImg) {
      return previewImg;
    }

    if (isMyAvatar) {
       return `${config.avatarsHost}/avatars/${currentUser.id}`
    }

    if (userId) {
      return `${config.avatarsHost}/avatars/${userId}`;
    }

    return `${config.api}/avatars/${username}`;
  })();

  const frozenUrl = frozenAvatars[src];

  const freeze = useCallback((e) => {
    memoFreezeImg(e.target);
  }, []);
  return (
    <div className={cx('Avatar', className, { 'Avatar--Uncontrolled': !controlled, 'Avatar--Controlled': controlled, 'Avatar--Hovering': hovering })} style={{ 
      // @ts-ignore
    '--size': size || undefined 
    }} {...props}>
        {/* frozen img */}
        <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--Frozen')} alt={`${username}'s avatar`} src={frozenUrl || (src)} />
        {/* normal img */}
        <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--NotFrozen')} onLoad={isStatic ? undefined : freeze} alt={`${username}'s avatar`} src={src} />
    </div>
  );
};

export function DefaultAvatar({ className = '', size = undefined, ...props }) {
  return (
    <div className={cx('Avatar', className)} style={{ 
      // @ts-ignore
    '--size': size || undefined 
    }} {...props}>
      <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--Frozen')} alt="Default avatar" src={DEFAULT_AVATAR} />
    </div>
  );
}

Avatar.propTypes = {
  /**
   * Deprecated, use 'user' instead or 'userId' 
   */
  username: PropTypes.string.isRequired,
  /**
   * Whether or not the 'hovering' state will be controlled by the parent component
   */
  controlled: PropTypes.bool,

  /**
   * When using the 'controlled' prop, this tells the component whether or not it should behave as if it was hovered
   */
  hovering: PropTypes.bool,

  /**
   * Class name for customizing the styles
   */
  className: PropTypes.string,

  user: PropTypes.shape({
    avatar: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),

  size: PropTypes.string,
  previewImg: PropTypes.string,

  /**
   * Piczel user id
   */
  userId: PropTypes.number,
};

export default Avatar;
