import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.scss';

const cx = classNames.bind(styles);

export const HomepageBanner = ({ children, className, titleOnly, ...props }) => (
  <div className={cx('HomepageBanner', {'HomepageBanner--NoDescription': titleOnly} , className)} {...props}>
    {children}
  </div>
);

export const HomepageBannerTitle = ({ children, className, ...props }) => (
  <div className={cx('HomepageBanner__Title', className)} {...props}>
    {children}
  </div>
);

export const HomepageBannerDescription = ({ children, className, ...props }) => (
  <div className={cx('HomepageBanner__Description', className)} {...props}>
    {children}
  </div>
);


export const HomepageBannerImage = ({ children, className, ...props }) => (
  <div className={cx('HomepageBanner__Image', className)} {...props}>
    {children}
  </div>
);

export const HomepageBannerCTA = ({ children, className, ...props }) => (
  <div className={cx('HomepageBanner__CTA', className)} {...props}>
    {children}
  </div>
);

