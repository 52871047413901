// State
const INITIAL_STATE = {};

// Actions
const SET_LOADING = 'piczel/loading/SET_LOADING';

// reducer
export default function loadingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        [action.section]: action.value,
      };

    default:
      return state;
  }
}

// Action creators
export function setLoading(section, value) {
  return {
    type: SET_LOADING,
    section,
    value,
  };
}

// selectors
/**
 * @param {LoadingState} state
 * @param {string} section
 * @returns {boolean}
 */
export function isLoading(state, section) {
  return state[section] === true;
}
