/* node_modules */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

/* local imports */
import Avatar from '~/components/Avatar';

/* style imports */
import styles from './ImagePreview.scss';

const cx = classnames.bind(styles);

export class ImagePreview extends Component {
  shouldComponentUpdate() {
    return true;
  }

  render() {
    const {
      id,
      is_video: isVideo,
      is_flash: isFlash,
      nsfw: isAdult,
      multi: isMulti,
      favorites_count: favoritesCount,
      title,
      image,
      images,
      thumbnail,
      pass,
      user: {
        username,
      },
      user,
      deleteImage,
      isBlacklisted,
      noUsernames,
      blur,
      onClick
    } = this.props;

    let thumb = image[isVideo || isFlash ? 'vthumb' : 'thumb'].url;

    if (thumbnail) thumb = thumbnail.image[isVideo || isFlash ? 'vthumb' : 'thumb'].url;

    if (isFlash) {
      thumb = '/img/piczelflashth.png';
    }

    return (
      <div
        className={cx({
          ImagePreview: true,
          'ImagePreview--noUsernames': noUsernames,
          'ImagePreview--blurred': blur,
        })}
      >
        {!noUsernames && (
        <Link to={`/gallery/${username}`} className={styles.ImagePreview__UserInfo}>
          <Avatar className={styles.ImagePreview__Avatar} username={username} user={user} />
          <span>{username}</span>
        </Link>
        )}

        <Link onClick={onClick && (e => onClick(id, e))} to={`/gallery/image/${id}${pass ? `/${pass}` : ''}`}>
          <div
            className={cx({
              ImagePreview__Thumb: true,
              'ImagePreview__Thumb--blacklisted': isBlacklisted,
            })}
            style={{
              backgroundImage: `url(${thumb})`,
            }}
          />
        </Link>
        <div
          title={`${favoritesCount} Favorites`}
          className={styles.ImagePreview__FavCount}
        >
          <i className="ion-heart" />
          {' '}
          {favoritesCount}
        </div>
        {deleteImage
            && (
            <div className={styles.ImagePreview__ActionList}>
              <i
                onClick={(e) => { deleteImage(id, e); }}
                className={cx({
                  ImagePreview__Action: true,
                  'ion-trash-a': true,
                })}
                title="Delete"
              />
              <Link
                to={`/gallery/image/${id}/edit`}
                className={cx({
                  ImagePreview__Action: true,
                  'ion-edit': true,
                })}
                title="Edit image"
              />
            </div>
            )
          }
        {isMulti && (
        <span className={styles.ImagePreview__MultiIndicator}>
          <i className="ion-ios-browsers " />
          {' '}
          {images.length}
        </span>
        )}
        <div className={styles.ImagePreview__ImageInfo}>
          <span>{title}</span>
        </div>
      </div>
    );
  }
}

export default ImagePreview;
