/**
 * Fetch with auth headers if available
 */
import _fetch from 'isomorphic-fetch';
import { setAuthHeaders, storeAuthHeaders } from '~/modules/auth';
import { authHeaders } from '~/services/storage';

/**
 * Makes the fetch function (with auth headers) available inside action creators
 * @param {Function} getState
 */
// eslint-disable-next-line import/prefer-default-export
export function configureFetch(getState, dispatch) {
  return function fetch(url, options = {}) {
    const state = getState();

    const { isSignedIn } = state.currentUser;
    const currentAuthToken = state.auth;

    if (isSignedIn) {
      if (options.headers) {
        options.headers = {
          ...currentAuthToken,
          ...options.headers,
        };
      } else {
        options.headers = currentAuthToken;
      }
    }

    return _fetch(url, options).then((response) => {
      const responseHeaders = response.headers;

      if (currentAuthToken['access-token'] && responseHeaders.get('access-token') && responseHeaders.get('access-token') !== currentAuthToken['access-token']) {
        const newAuthHeaders = {
          'access-token': responseHeaders.get('access-token'),
          client: responseHeaders.get('client'),
          uid: responseHeaders.get('uid'),
        };

        dispatch(setAuthHeaders(newAuthHeaders));
        storeAuthHeaders(responseHeaders, {});
      }

      return response;
    });
  };
}
