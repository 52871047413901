import Config from '~/config';
import { addFlash } from './flashes';
import { setLoading } from './loading';

const INITIAL_STATE = {
  submissions: [],
  submission: null,
};

const SET_SUBMISSION_LIST = 'SET_SUBMISSION_LIST';
const SET_SUBMISSION = 'SET_SUBMISSION';

const mascotContestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SUBMISSION:
      return {
        ...state,
        submission: action.payload,
      };

    case SET_SUBMISSION_LIST:
      return {
        ...state,
        submissions: action.append ? [...state.submissions, ...action.payload] : action.payload,
      };

    default:
      return state;
  }
};

export default mascotContestReducer;

export const setSubmission = payload => ({
  type: SET_SUBMISSION,
  payload,
});

export const setSubmissionList = payload => ({
  type: SET_SUBMISSION_LIST,
  payload,
});

export const sendSubmission = imageId => async (dispatch, getState, fetch) => {
  dispatch(setLoading('contestsubmit', true));

  const response = await fetch(`${Config.api}/mascotcontest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      gallery_image_id: imageId,
    }),
  });

  dispatch(setLoading('contestsubmit', false));

  if (response.ok) {
    const json = await response.json();

    if (json.errors) {
      Object.keys(json.errors).forEach((key) => {
        json.errors[key].forEach(msg => dispatch(addFlash('error', msg))); 
      });
    } else {
      dispatch(setSubmission(json));
    }
  }
};

export const loadSubmissions = (admin = false) => async (dispatch, getState, fetch) => {
  dispatch(setLoading('contestsubmissions', true));

  const response = await fetch(`${Config.api}/mascotcontest${admin ? '?view=admin' : ''}`);

  dispatch(setLoading('contestsubmissions', false));

  if (response.ok) {
    const json = await response.json();

    if (admin && Array.isArray(json)) {
      json.sort((a, b) => b.votes - a.votes);
    }

    dispatch(setSubmissionList(json));
  }
};

export const deleteSubmission = id => async (dispatch, getState, fetch) => {
  dispatch(setLoading('contestsubmissions', true));

  const response = await fetch(`${Config.api}/mascotcontest/${id}`, {
    method: 'DELETE',
  });

  if (response.ok) {
    const { submissions } = getState().mascotContest;

    return dispatch(setSubmissionList(submissions.filter(sub => sub.id !== id)));
  }
};

export const rewardSubmission = id => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/mascotcontest/${id}/reward`, {
    method: 'POST',
  });

  if (response.ok) {
    const submission = await response.json();

    const submissions = getSubmissionList(getState());

    dispatch(setSubmissionList(submissions.map(sub => (sub.id === id ? submission : sub))));
  }
};

export const loadPublicSubmissions = () => async (dispatch, getState, fetch) => {
  dispatch(setLoading('contestsubmissions', true));

  const response = await fetch(`${Config.api}/mascotcontest/entries`);

  dispatch(setLoading('contestsubmissions', false));

  if (response.ok) {
    const json = await response.json();

    dispatch(setSubmissionList(json));
  }
};

export const voteEntry = (id, value = true) => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/mascotcontest/${id}/vote`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      value,
    }),
  });

  const json = await response.json();

  if (response.ok && json.status !== 'error') {
    const submissions = getSubmissionList(getState());

    dispatch(setSubmissionList(submissions.map(sub => (sub.id === id ? json : sub))));
  }

  return json;
};

export const getSubmissionList = state => state.mascotContest.submissions;

export const getSubmission = state => state.mascotContest.submission;
