import React, {
  useRef, useEffect, useState, useCallback, useMemo, useContext,
} from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import className from 'classnames/bind';
import { Waypoint } from 'react-waypoint';
import useResizeObserver from '@react-hook/resize-observer';
import { debounce } from 'debounce';

import {
  SoundOptions, getHighlightedMessage, getOptions, isMessageWithMention, setHighlightedMessage,
} from '~/modules/chat';
import { ChatContext, events } from './ChatContext';
import { getRoomMessages } from '~/modules/chatMessages';

import Message from './Message';
import Modal from './Modal';
import Button from '../Button';

import NewMessage from './NewMessage.mp3';
import styles from './MessagePane.scss';

const HELP_ROOM = 'piczel-help';
const cx = className.bind(styles);
const JUMP = 50;
const LOADMORE = 5;
const MAXMESSAGES = 100;


const useHeight = (target) => {
  const [height, setHeight] = React.useState(0);

  useEffect(() => {
    if (target.current) {
      setHeight(target.current.getBoundingClientRect().height);
    }
  }, [target]);

  useResizeObserver(target, entry => setHeight(entry.contentRect.height));
  return height;
};


const roomDisabledLocale = {
  kick: {
    title: {
      id: 'RoomDisabledTitle_Kick',
      defaultMessage: 'Kicked',
    },

    body: {
      id: 'RoomDisabledBody_Kick',
      defaultMessage: "You've been kicked from this room",
    },
  },

  ban: {
    title: {
      id: 'RoomDisabledTitle_Ban',
      defaultMessage: 'Banned',
    },

    body: {
      id: 'RoomDisabledBody_Ban',
      defaultMessage: "You've been banned",
    },
  },

  anon: {
    title: {
      id: 'RoomDisabledTitle_Anon',
      defaultMessage: 'Sign in to chat',
    },

    body: {
      id: 'RoomDisabledBody_Anon',
      defaultMessage: 'Anonymous users are not allowed to participate in this room',
    },
  },

  blocked: {
    title: {
      id: 'RoomDisabledTitle_Blocked',
      defaultMessage: 'Blocked',
    },
    body: {
      id: 'RoomDisabledBody_Blocked',
      defaultMessage: 'You can\'t message this user',
    },
  },
};

let lastPlay = null;
const scrollTolerance = 40; // px


const isRoughlyTheSame = (a, b) => Math.abs(a - b) <= scrollTolerance;


const MessagePane = ({ currentRoom , currentUser, setOverview, sidePane, lockToBottom }) => {
  const {
    disabled,
    deletion_enabled: roomDeletion,
    reason,
    id: roomId
  } = currentRoom;
  const messages = useSelector(getRoomMessages);

  /**
   * @type {React.MutableRefObject<HTMLDivElement?>}
   */
  const pane = useRef(null);
  /**
   * @type {React.MutableRefObject<HTMLAudioElement?>}
   */
  const audio = useRef(null);
  const list = useRef(null);
  const { client: socket } = useContext(ChatContext);
  const [deleting, setDeleting] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [startSlice, setStartSlice] = useState(1);
  const [initScroll, setInitScroll] = useState(false);
  const [loadedRoom, setLoadedRoom] = useState(false);
  const height = useHeight(list);

  const {
    animateGifs,
    playSounds,
    disableTimestamps,
    displayColors,
    emojiSize,
    chatFontSize,
    soundOnlyWhenUnfocused,
    messageDisplay,
  } = useSelector(getOptions);

  const dispatch = useDispatch();

  const rooms = useSelector(state => state.entities.chatRooms);

  const highlightedMessage = useSelector(getHighlightedMessage);

  const deleteMessage = useCallback((id) => {
    if (socket) {
      socket.emit(events.DELETE_MESSAGE, {
        messageId: id,
      });

      setDeleting(null);
    }
  }, [socket]);

  useEffect(() => {
    setScrolled(false);
    setInitScroll(false);
    setLoadedRoom(false);
    setStartSlice(1);
  }, [roomId]);

  useEffect(() => {
    if (!audio.current) {
      audio.current = document.createElement('audio');
      audio.current.src = NewMessage;
      audio.current.loop = false;
    }

    let focused = 1;
    const el = audio.current;

    window.onfocus = () => {
      focused = 1;
    };

    window.onblur = () => {
      focused = 0;
    };

    function onNewMessage({ data: { roomId: messageRoomId, message } }) {
      if (playSounds === SoundOptions.never) return;
      if (
        playSounds === SoundOptions.mentions && currentUser
        && !isMessageWithMention(currentUser, message)
      ) return;

      const isChatFocused = (messageRoomId === roomId && (document.visibilityState === 'visible' && focused));

      if (isChatFocused && soundOnlyWhenUnfocused) return;

      if (message.user && message.user.uid !== currentUser?.uid) {
        if (!lastPlay || ((Date.now() - lastPlay) > 5000)) {
          if ((messageRoomId in rooms && rooms[messageRoomId].name !== HELP_ROOM) || currentUser?.role === 'admin') {
            try {
              el.play();
              lastPlay = Date.now();
            } catch (e) {
              console.error(`.play() blocked by browser`);
            }
          }
        }
      }
    }

    if (socket) {
      socket.on(events.ADD_MESSAGE, onNewMessage);

      return () => {
        delete window.onfocus;
        delete window.onblur;

        socket.off(events.ADD_MESSAGE, onNewMessage);
      };
    }
  }, [socket, playSounds, currentUser, roomId, rooms, soundOnlyWhenUnfocused]);

  useEffect(() => {
    if (!scrolled && list.current) {
      list.current?.scrollTo(0,list.current?.scrollHeight);
    }
  }, [height, scrolled]);

  const calcSlice = (number) => {
    const calc = messages.length - number * JUMP;
    return calc < 0 ? 0 : calc;
  };

  const onLoad = (index) => {
    const doScroll = !scrolled && index === messages.length - 1;
    return (e) => {
      const linkExt = e.target.src;
      if (doScroll && linkExt && linkExt.slice(0, 4) === 'http') {
        list.current?.scrollTo(0,list.current?.scrollHeight);
      }
    };
  };

  const onLoadPreview = (index) => {
    const doScroll = !scrolled && index === messages.length - 1;
    return () => {
      if (doScroll) {
        list.current?.scrollTo(0,list.current?.scrollHeight);
      }
    };
  };

  const loadRoom = debounce(() => {
    if (!loadedRoom) {
      list.current?.scrollTo(0,list.current?.scrollHeight);
      setLoadedRoom(true);
    }
  }, 250);

  const repositionScroll = (bottomY) => {
    const oldPosition = list.current?.scrollHeight - bottomY;
    list.current?.scrollTo(0, oldPosition);
    setInitScroll(true);
  };

  const loadMessages = () => {
    const max = Math.ceil(messages.length / JUMP);
    // @ts-ignore
    const bottomY = list.current?.scrollHeight - list.current?.scrollTop;
    if (initScroll && startSlice < max) {
      setInitScroll(false);
      setStartSlice(startSlice + 1);
      repositionScroll(bottomY);
    }
  };

  const onScroll = useMemo(() => debounce(() => {
    if (list.current) {
      const { clientHeight, scrollHeight, scrollTop } = list.current;
      const overScrolled = scrollTop + clientHeight >= scrollHeight;
      /**
       * Determine if we should display the 'older messages' warning
       */
      const scrolledUp = (clientHeight > 0 && scrollHeight > 0
        && scrollHeight > clientHeight
        && !isRoughlyTheSame(scrollTop + clientHeight, scrollHeight));
      requestAnimationFrame(() => {
        setScrolled(scrolledUp && !overScrolled);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 200), [sidePane, scrolled]);

  useEffect(() => {
    if (!highlightedMessage) return;

    const index = messages.findIndex(message => (Array.isArray(message) ? message.some(subMsg => subMsg.id === highlightedMessage) : message.id === highlightedMessage));

    if (index !== -1) {
      // list.current?.scrollToRow(index);
      dispatch(setHighlightedMessage(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedMessage]);

  const resetStartSlice = debounce(() => {
    setInitScroll(true);
    const reset = (messages.length - calcSlice(startSlice)) > MAXMESSAGES;
    if (reset) {
      setStartSlice(Math.ceil(MAXMESSAGES / JUMP));
    }
  }, 10);

  useEffect(() => {
    if (!scrolled) {
      requestAnimationFrame(() => {
        if (list.current) {
          resetStartSlice();
          // eslint-disable-next-line no-unused-expressions
          list.current?.scrollTo(0,list.current?.scrollHeight);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, scrolled]);

  return (
    <>
      {
        deleting && (
          <Modal title={<FormattedMessage id="Chat_DeleteMessage" defaultMessage="Delete message" />} close={() => setDeleting(null)}>
            <div style={{ textAlign: 'center' }}>
              <FormattedMessage id="Chat_DeleteMessagePrompt" defaultMessage="Delete this message?" />
            </div>

            <div style={{ textAlign: 'center' }}>
              <Button onClick={() => setDeleting(null)}>
                <FormattedMessage id="Chat_DeleteCancel" defaultMessage="Cancel" />
              </Button>
              <Button color="red" onClick={() => deleteMessage(deleting)}>
                <FormattedMessage id="Chat_DeleteConfirm" defaultMessage="Delete" />
              </Button>
            </div>
          </Modal>
        )
      }
      {
        /**
         * When present, `disabled` will be a string identifying why the room has been disabled for us
         * so currently it'll be either 'kick' or 'ban'
         */
        disabled && (
          <Modal title={<FormattedMessage {...roomDisabledLocale[disabled].title} />}>
            <div style={{ textAlign: 'center' }}>
              <FormattedMessage {...roomDisabledLocale[disabled].body} />
            </div>

            {
              disabled === 'anon' && (
                <div style={{ textAlign: 'center' }}>
                  <Button to="/signup">
                    <FormattedMessage id="Chat_AnonSignUp" defaultMessage="Sign up" />
                  </Button>
                  &nbsp;
                  <FormattedMessage id="Chat_AnonOr" defaultMessage="or" />
                  &nbsp;
                  <Button to={`/login?referral=${encodeURIComponent(window.location.pathname)}`}>
                    <FormattedMessage id="Chat_AnonSignIn" defaultMessage="Sign in" />
                  </Button>
                </div>
              )
            }
          </Modal>
        )
      }


      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div onLoad={loadRoom} style={{ position: 'relative' }}>
        <div
          style={{ '--emoji-size': `${emojiSize}px`, '--chat-font-size': `${chatFontSize}px` }}
          className={cx('MessagePane', { 'MessagePane--Disabled': disabled, 'MessagePane--Locked': lockToBottom })} 
          ref={list}
          onScroll={onScroll}
        >
          {
            messages.map((message, index) => {
              if (index < calcSlice(startSlice)) {
                return null;
              }
              const mainMessage = Array.isArray(message) ? message[0] : message;
              return (
                <>
                  {
                    index === (calcSlice(startSlice) + LOADMORE)
                      ? <Waypoint onEnter={loadMessages} />
                      : null
                  }
                  <div className={styles.MessagePane__Item} key={index}>
                    <Message
                      disableTimestamps={disableTimestamps}
                      currentUser={currentUser}
                      onLoad={onLoad(index)}
                      onLoadPreview={onLoadPreview(index)}
                      animateGifs={animateGifs}
                      displayColors={displayColors}
                      display={messageDisplay}
                      setOverview={setOverview}
                      message={message}
                      remove={(!mainMessage.serverMessage || (mainMessage.serverMessage && mainMessage.text?.startsWith('<i>'))) && currentUser && (currentUser.permissions.remove || (roomDeletion && mainMessage.user?.uid === currentUser.uid)) && setDeleting}
                    />
                  </div>
                </>
              );
            })
          }
        </div>
        {
          scrolled && (
            <button onClick={() => setScrolled(false)} type="button" className={styles.MessagePane__ScrollWarning}>
              <FormattedMessage id="Chat_OlderMessages" defaultMessage="You are viewing older messages" />

              <b role="img" className="ion-android-arrow-dropdown-circle" />
            </button>
          )
        }
      </div>
    </>
  );
};


MessagePane.propTypes = {
  // messages: arrayOf(shape({
  //   id: number,
  //   text: string,
  //   user: shape({
  //     id: string,
  //     username: string,
  //   }),
  // })).isRequired,
  onClick: func,
};

MessagePane.defaultProps = {
  onClick: null,
};

export default MessagePane;
