// eslint-disable-next-line spaced-comment
/// <reference path="../types/state.d.ts" />
/// <reference path="../types/streams.d.ts" />
// eslint-disable-next-line spaced-comment

import { denormalize, normalize, schema } from 'normalizr';
import { batch } from 'react-redux';
import memoize from 'memoize-one';
import isEqual from 'lodash.isequal';

import { setLoading } from './loading';
import { sortById } from '~/helpers/data';
import Config from '~/config';
import { getFromId } from './pagination';
import errorHandling from './_errorHandling';
import { addFlash } from './flashes';
import { userSchema } from './user';
import { updateCurrentUser } from './currentUser';
import { ADD_ENTITIES } from './entity';
import { createSelector, createSelectorCreator } from 'reselect';
import { batchActions } from 'redux-batched-actions';

/**
 * How many streams a free user can add to the watch page
 * @type {number}
 */
export const FREE_STREAM_LIMIT = 2;
/**
 * How many streams a premium user can add to the watch page
 * @type {number}
 */
export const PREMIUM_STREAM_LIMIT = Infinity;

export const STREAMSHOTS_BITRATE_THRESHOLD = 6000000;

// Sections
export const STREAMS_PAGE = 'streams';
export const HOME_STREAM_LIST = 'piczel/home/STREAM_LIST';
export const ADD_STREAM_LIST = 'piczel/watch/STREAM_LIST';
export const PROMPTING = 'piczel/watch/PROMPTING';
export const STREAMS_PLAYER = 'piczel/watch/STREAMS_PLAYER';

// Watch viewtypes
export const [TYPE_SINGLE, TYPE_MULTI, TYPE_CUSTOM] = [0, 1, 2];

export const FETCHING_STREAMS = 'piczel/streams/FETCHING_STREAMS';

// Schema
export const streamSchema = new schema.Entity('streams', {
  user: userSchema,
}, { idAttribute: 'username' });

// Streams reducer

/**
 * State variables specific to the Watch Page, including players state and
 * UI settings like chat width
 * @type {WatchUIState}
 */
const WATCH_UI_STATE = {
  is404: false,
  addingStream: false,
  viewType: TYPE_SINGLE,
  descHidden: false,
  focused: null,
  chatWidth: null,
  passwords: {},
  playingRecordings: {},
  playbackKeys: {},
};

/**
 * State design based on this recipe
 * https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape#designing-a-normalized-state
 * @type {StreamsState}
 */
const INITIAL_STATE = {
  ssr: false, // Whether or the stream data was loaded on the server

  streams: [],
  username:'',

  sections: {
    playing: [],
    closed: [],
  }, // Stores usernames for each section
};

// actions
const SET_STREAMS = 'piczel/streams/SET_STREAMS';
const SET_SECTION = 'piczel/streams/SET_SECTION';
const ADD_STREAM = 'ADD_STREAM';
const SET_STREAMS_PAGE = 'SET_STREAMS_PAGE';
const ADD_TO_SECTION = 'ADD_TO_SECTION';
const SET_USERNAME = 'SET_USERNAME';

// ui actions
const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
const SET_STREAM_FOCUS = 'SET_STREAM_FOCUS';
const RESIZE_CHAT = 'RESIZE_CHAT';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_ADDING_STREAM = 'SET_ADDING_STREAM';
export const SET_PASSWORDS = 'SET_PASSWORDS';
export const SET_PLAYING_RECORDINGS = 'SET_PLAYING_RECORDINGS';
export const START_PLAYING_RECORDING = 'START_PLAYING_RECORDING';
export const SET_PLAYBACK_KEY = 'SET_PLAYBACK_KEY';
export const SET_SSR = 'SET_SSR';
const SET_404 = 'SET_404';

// reducer
/**
 * Watch page UI state
 * @param {WatchUIState} state
 * @param {import('redux').AnyAction} action
 * @returns {WatchUIState}
 */
export function watchUIReducer(state = WATCH_UI_STATE, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        [`${action.bar}Hidden`]: !state[`${action.bar}Hidden`],
      };

    case SET_STREAM_FOCUS:
      return {
        ...state,
        focused: action.payload,
      };

    case RESIZE_CHAT:
      return {
        ...state,
        chatWidth: action.width,
      };

    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case SET_ADDING_STREAM:
      return {
        ...state,
        addingStream: action.payload,
      };

    case SET_PASSWORDS:
      return {
        ...state,
        passwords: action.payload,
      };

    case SET_PLAYING_RECORDINGS:
      return {
        ...state,
        playingRecordings: action.payload,
      };

    case START_PLAYING_RECORDING:
      return {
        ...state,
        playingRecordings: {
          ...state.playingRecordings,
          [action.username]: action.recordingId,
        },
      };

    case SET_PLAYBACK_KEY:
      return {
        ...state,
        playbackKeys: {
          ...state.playbackKeys,
          [action.username]: action.key,
        },
      };

    case SET_404:
      return {
        ...state,
        is404: action.payload,
      };

    default:
      return state;
  }
}

/**
 * @param {StreamsState} state
 * @param {import('redux').AnyAction} action
 * @returns {StreamsState}
 */
export default function streamsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_STREAMS:
      return {
        ...state,
        streams: action.payload,
      };

    case SET_SECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.section]: [...action.payload],
        },
      };

    case ADD_STREAM:
      return {
        ...state,
        streams: [...state.streams, action.payload],
      };

    case ADD_TO_SECTION:
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.section]: state.sections[action.section] ? [...state.sections[action.section], action.username] : [action.username],
        },
      };

    case SET_SSR:
      return { ...state, ssr: action.payload };

    case SET_USERNAME:
      return {
        ...state,
        username:action.payload
      };

    default:
      return state;
  }
}

// action creators
export function setStreams(payload) {
  return { type: SET_STREAMS, payload };
}

/**
 *
 * @param {string} section
 * @param {string[]} payload
 */
export function setSection(section, payload) {
  return { type: SET_SECTION, section, payload };
}

/**
 * Toggles a watch page sidebar
 * @param {String} bar
 */
export function toggleBar(bar) {
  return { type: TOGGLE_SIDEBAR, bar };
}

/**
 * Fetches streams into store, if a section is specified it puts a list of usernames
 * in state.section[sectionName].
 * @param {StreamsParameters} [options] Extra GET parameters for the request
 * @param {string|null} [section]
 */
export function fetchStreams(options = {}, section = null) {
  return function (dispatch, getState, fetch) {
    if (!options.silent) dispatch(setLoading(FETCHING_STREAMS, true));
    dispatch(set404(false));

    const endpoint = new URL(`${Config.api}/streams`);
    Object.keys(options).forEach(key => endpoint.searchParams.append(key, options[key]));

    return fetch(endpoint)
      .then((response) => {
        if (response.status === 404) {
          dispatch(set404(true));
        }

        return errorHandling(dispatch, response);
      })
      .then((streams) => {
        const normalized = normalize(streams, [streamSchema]);

        dispatch({
          type: ADD_ENTITIES,
          payload: normalized.entities,
        });
      
        if (section) {
          dispatch(setSection(section, normalized.result));
        }

        dispatch(setLoading(FETCHING_STREAMS, false));
      });
  };
}

export function fetchStream(username) {
  return async function asyncStreams(dispatch, getState, fetch) {
    let response = await fetch(`${Config.api}/streams/${username}`);

    response = await response.json();
    if (response.errors && !response?.meta?.wrong_pass) return response;

    if (response?.meta?.wrong_pass) {
      response = {
        data: response.meta.wrong_pass,
      };
    }

    const normalized = normalize(response.data, [streamSchema]);

    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });

    response.data.forEach((stream) => {
      dispatch(addStream(stream.username));
    });

    return response;
  };
}

/**
 * @param {String} username
 * @param {Partial<Stream>} data
 */
export function updateStream(username, data) {
  const normalized = normalize({ ...data, username }, streamSchema);

  return {
    type: ADD_ENTITIES,
    payload: normalized.entities,
  };
}

/**
 * Adds a stream to the general state
 * @param {string} username
 */
export function addStream(username) {
  return {
    type: ADD_STREAM,
    payload: username,
  };
}

export function setStreamsPage(streams) {
  return {
    type: SET_STREAMS_PAGE,
    usernames: streams.map(stream => stream.username),
  };
}

/**
 * @param {string[]} usernames
 */
export function startPlayingStreams(usernames) {
  if (!Array.isArray(usernames)) {
    // eslint-disable-next-line no-param-reassign
    usernames = [usernames];
  }

  return async function (dispatch) {
    dispatch(set404(false));

    dispatch(setLoading(STREAMS_PLAYER, true));
    // Clear prompting streams
    dispatch(setSection(PROMPTING, []));

    const streams = usernames.map(username => dispatch(fetchPlayingStream(username)));

    await Promise.all(streams);

    dispatch(setLoading(STREAMS_PLAYER, false));
  };
}
/**
 *
 * @param {Stream} stream
 */
export function addPlayingStream({ username }) {
  return {
    type: ADD_TO_SECTION,
    section: 'playing',
    username,
  };
}

/**
 * @param {string} username
 */
export function addPrompting(username) {
  return {
    type: ADD_TO_SECTION,
    section: PROMPTING,
    username,
  };
}

/**
 * @param {string} username
 * @param {boolean} ignoreMulti If true, only the requested stream will be present in the response
 */
export function fetchPlayingStream(username, ignoreMulti = false) {
  return async function (dispatch, getState, fetch) {
    /**
     * @type {RootState}
     */
    const { watchUI: { passwords, playbackKeys }, streams: { sections: { playing, [PROMPTING]: prompting }, username: oldUsername }, entities: { streams: currentStreams } } = getState();
    const url = new URL(`${Config.api}/streams/${username}`);
    if (Object.keys(passwords).length > 0) {
      url.searchParams.set('passwords', btoa(JSON.stringify(passwords)));
    }

    /**
     * @type {Response}
     */
    const response = await fetch(url.toString());
    const streams = await response.json();

    if (response.status === 404) {
      dispatch(set404(true));
    }

    if (response.ok) {
      const normalized = normalize(streams.data, [streamSchema]);
      const sameLength = streams.data.length === Object.keys(currentStreams).length;

      dispatch({
        type: ADD_ENTITIES,
        payload: normalized.entities,
      });
      
      streams.data.forEach((stream) => {
        batch(() => {
          if (!(stream.username in currentStreams)) {
            dispatch(addStream(stream));
          }

          if (playing.indexOf(stream.username) === -1 || (username !== oldUsername && sameLength)) {
            if (!ignoreMulti || (ignoreMulti && stream.username === username)) {
              dispatch(addPlayingStream(stream));
            }
          }
        });
      });
      dispatch({
        type: SET_USERNAME,
        payload: username
      });
    } else if (response.status === 401) {
      batch(() => {
        streams.meta.wrong_pass.forEach((stream) => {
          if (!(stream.username in currentStreams)) {
            const normalized = normalize(stream, streamSchema);

            dispatch({
              type: ADD_ENTITIES,
              payload: normalized.entities,
            });
          } else if (stream.username in passwords) {
            dispatch(addFlash('error', `Wrong password for stream: ${stream.username}`));
          }

          if (!prompting || prompting.indexOf(stream.username) === -1) {
            dispatch(addPrompting(stream.username));
          }
        });
      });
    }
  };
}

export function followStream(streamName, value = true) {
  return async function (dispatch, getState, fetch) {
    const response = await fetch(`${Config.api}/users/me/follow/${streamName}`, {
      method: value ? 'POST' : 'DELETE',
    });

    const normalized = normalize({
      username: streamName,
      following: {
        value,
      },
    }, streamSchema);

    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });
  };
}

// Offline image and banner
export function updateStreamImage(username, data, type = 'offline_image') {
  return function (dispatch, getState, fetch) {
    return fetch(`${Config.api}/streams/${username}/${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    })
      .then(response => errorHandling(dispatch, response));
  };
}

export function deleteStreamImage(username, type = 'offline_image') {
  return function (dispatch, getState, fetch) {
    return fetch(`${Config.api}/streams/${username}/${type}`, {
      method: 'DELETE',
    })
      .then(response => response.json());
  };
}

export function deleteRecording(username, recordingId) {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/streams/${username}/recordings/${recordingId}`, {
      method: 'DELETE',
    });

    if (response.ok) {
      const currentUser = getState().currentUser.data;

      dispatch(updateCurrentUser(username, {
        ...currentUser,
        stream: {
          ...currentUser.stream,
          recordings: currentUser.stream.recordings.filter(recording => recording.id === recordingId),
        },
      }));
    }
  };
}

export function setPlayingStreams(usernames) {
  return setSection('playing', usernames);
}

export function resizeChat(width) {
  return {
    type: RESIZE_CHAT,
    width,
  };
}

/**
 * Sets currently focused stream
 * @param {string|null} username
 */
export function setStreamFocus(username) {
  return { type: SET_STREAM_FOCUS, payload: username };
}


/**
 * Sets the current view type
 * @param {ViewType} viewType
 */
export function setViewType(viewType) {
  return { type: SET_VIEW_TYPE, payload: viewType };
}

/**
 * @param {boolean} value
 */
export function setAddingStream(value) {
  return { type: SET_ADDING_STREAM, payload: value };
}

/**
 * @param {Stream} stream
 * @param {number|null} recordingId
 */
export function startPlayingRecording(stream, recordingId) {
  return {
    type: START_PLAYING_RECORDING,
    username: stream.username,
    recordingId,
  };
}

/**
 * Enqueues a timelapse to be created in the server
 * @param {number} recordingId
 * @returns {AsyncAction}
 */
export function requestMakeTimelapse(recordingId) {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/recording/${recordingId}/timelapse`, {
      method: 'POST',
    });

    const json = await errorHandling(dispatch, response);

    return json;
  };
}

/**
 * Request a rtmp playback key for a stream
 * @param {string} username
 * @returns {AsyncAction}
 */
export function requestPlaybackKeys(usernames) {
  return async (dispatch, getState, fetch) => {
    const response = await fetch(`${Config.api}/playback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usernames,
      }),
    });

    const keys = await response.json();
    
    dispatch(batchActions(
      keys.map((key) => ({
        type: SET_PLAYBACK_KEY,
        username: key.username,
        key,
      }))
    ));
  };
}

export function removeStream(username) {
  return (dispatch, getState) => {
    /**
     * @type {RootState}
     */
    const { streams: { sections: { closed } }, watchUI: { focused } } = getState();

    if (focused === username) {
      dispatch(setStreamFocus(null));
    }

    dispatch(setSection('closed', [...closed, username]));
    dispatch(setViewType(TYPE_CUSTOM));
  };
};

export const updateRecording = (id, data) => async (dispatch, getState, fetch) => {
  const response = await fetch(`${Config.api}/recordings/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    const json = await response.json();
    if (!json.error || !json.errors) {
      const currentUser = getState().currentUser.data;

      dispatch(updateCurrentUser(currentUser.username, {
        ...currentUser,
        stream: {
          ...currentUser.stream,
          recordings: currentUser.stream.recordings.map(recording => recording.id === id ? json : {...recording, ...json}),
        },
      }));
    }
  }
};

/**
 * Whether or not to show the 404 view
 * @param {boolean} value
 * @returns {import('redux').Action<SET_404>}
 */
export function set404(value) {
  return {
    type: SET_404,
    payload: value,
  };
}

// selectors
/**
 *
 * @param {RootState} state
 * @param {string} section
 */
function mapStreams(state, section) {
  /**
   * TODO: Figure a better way to prevent duplicate streams
   *
   * this works but it's not very clean
   */
  let streams = state.streams[section];

  streams = streams.reduce((arr, username) => {
    if (arr.indexOf(username) === -1) {
      return [...arr, username];
    }

    return arr;
  }, []);

  return streams.map(username => state.streams.streams.byUsername[username]);
}

const streamsSelector = createSelectorCreator(
  memoize,
  isEqual
);

export const getPlayingUsernames = streamsSelector(
  state => state.streams.sections,
  sections => sections['playing']
);

/**
 * @param {RootState} state
 * @returns {Stream[]} streams
 */
export const getPlayingStreams = createSelector(
  getPlayingUsernames,
  state => state.entities.users,
  state => state.entities.streams,
  (usernames, users, streams) => denormalize(usernames, [streamSchema], { users, streams }),
);

/**
 * @param {RootState} state
 */
export function getStreamsPage(state) {
  return mapStreams(state, 'page');
}

export function getStreamByUsername(state, username) {
  return state.entities.streams[username] || null;
}

/**
 * @param {RootState} state
 * @param {string} section
 * @returns {Stream[]}
 */
export function getStreamsByUsernames(state, section) {
  if (!state.streams.sections[section]) return [];

  return denormalize(state.streams.sections[section], [streamSchema], state.entities);
}

/**
 * @param {RootState} state
 * @returns {Stream[]}
 */
export function getAddStreamList(state) {
  const streams = getStreamsByUsernames(state, ADD_STREAM_LIST);
  return streams.filter(stream => !stream['isPrivate?'] && state.streams.sections.playing.indexOf(stream.username) === -1);
}

export function getChatWidth(state) {
  return state.watchUI.chatWidth;
}

export function isDescriptionHidden(state) {
  return state.watchUI.descHidden;
}

/**
 * @param {RootState} state
 */
export function addStreamEnabled(state) {
  const limit = (state.currentUser.data && state.currentUser.data['premium?']) ? PREMIUM_STREAM_LIMIT : FREE_STREAM_LIMIT;

  return state.streams.sections.playing.length < limit;
}

/**
 * Gets the stream usernames that require a password
 * @param {RootState} state
 * @returns {string[]}
 */
export function getPromptingUsernames(state) {
  return state.streams.sections[PROMPTING] || [];
}

/**
 * If in a multi, returns the parent
 * @param {RootState} state
 */
export function getMainStream(state) {
  const { viewType } = state.watchUI;

  const streams = getPlayingStreams(state);

  if (viewType === TYPE_MULTI) {
    return streams.find(stream => stream.in_multi && !stream.parent_streamer);
  }

  return streams[0];
}

/**
 * @param {RootState} state
 */
export function getViewType(state) {
  return state.watchUI.viewType;
}

/**
 * @param {RootState} state
 * @returns {WatchUIState['playingRecordings']} the state of the playing recordings
 */
export function getPlayingRecordings(state) {
  return state.watchUI.playingRecordings;
}

/**
 * Whether or not a stream is playing something
 * Does not necessarily mean the stream is live
 *
 * @param {RootState} state
 * @param {Stream} stream
 */
export function isStreamPlaying(state, { username }) {
  return state.entities.streams[username].live || getPlayingRecordings(state)[username];
}

/**
 * Get the current playback keys
 * @param {RootState} state
 */
export function getPlaybackKeys(state) {
  return state.watchUI.playbackKeys;
}

export function isLargeMulti(state) {
  const streams = getPlayingStreams(state);

  return streams.filter(stream => stream.live).length > 4;
}

/**
 * Whether or not the 404 error should be visible
 * @param {StreamsState} state
 * @returns {boolean}
 */
export function is404(state) {
  return state.watchUI.is404;
}
