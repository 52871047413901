import { tones } from '@piczel/emoji';
import { createAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { denormalize, normalize, schema } from 'normalizr';
import { batchActions } from 'redux-batched-actions';
import { createSelector } from 'reselect';
import config from '~/config';
import { chatMessageSchema, chatRoomSchema, chatUserSchema } from './chatEntities';
import { updateHistory } from './chatMessages';
import { ADD_ENTITIES } from './entity';
import { handleError } from './flashes';

const MAX_RECENTLY_USED = 25;

export { groupMessages } from './chatEntities';

export const SoundOptions = {
  never: 'never',
  always: 'playsounds',
  mentions: 'mentions',
};

/**
 * @type {ChatState}
 */
const INITIAL_STATE = {
  accessToken: null,
  uid: null,
  /**
   * Currently-joined rooms
   */
  rooms: [],

  /**
   * List of room names that we're trying to join
   */
  pendingRooms: [],
  
  // roomId: messageList mapping
  messages: {},
  // roomId: users mapping
  users: {},

  blockList: [],
  activeRoom: null,
  highlightedMessage: null,
  globalEmoticons: [],
  modalOpen: null, // is the raffle dialog open
  emoticonCategories: {}, // these change each time the current room changes
  usedEmoticons: [],
  notificationReminder: true,
  performingAction: null, // are we about to kick or ban someone?
  nsfwEmotesHidden: false, // display 'some emotes may be hidden by your nsfw filter'
  openingPM: null,
  sidePaneWidth: 0,
  autocompleteQuery: '',

  showingRaffleEntrants: false,

  options: {
    color: '#3db9ea',
    displayColors: true,
    emojiTone: tones.base,
    mentionNotifications: false, // direct mentions
    roleMentionNotifications: true, // role mentions
    everyoneMentionNotifications: true,

    animateGifs: true,
    disableTimestamps: false,
    playSounds: SoundOptions.mentions,
    soundOnlyWhenUnfocused: true,
    /**
     * Since <FormattedMessage /> will use these keys directly for ids, I added the Chat_ prefix to prevent any weird collisions
     */
    Chat_joinRoom: false,
    Chat_leaveRoom: false,
    Chat_ban: true,
    Chat_topic: true,
    emojiSize: 200,
    viewLayout: 'Auto',
    zoom: 1,
    messageDisplay: 'normal', // normal = Shown as 'cozy'
    pmsDisplay: 'tab',
    chatFontSize: 16, // px
    initialWidth: 500,
    raffleWinnerAnimation: true,
  },
};

const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const JOIN_ROOM = 'JOIN_ROOM';
const LEAVE_ROOM = 'LEAVE_ROOM';
const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';
const CLEAR_ROOMS = 'CLEAR_ROOMS';
const SET_HIGHLIGHTED_MESSAGE = 'SET_HIGHLIGHTED_MESSAGE';
const SET_EMOTICON_CATEGORIES = 'SET_EMOTICON_CATEGORIES';
const SET_GLOBAL_EMOTICONS = 'SET_GLOBAL_EMOTICONS';
export const SET_USED_EMOTES = 'SET_USED_EMOTES';
const SET_OPTIONS = 'SET_OPTIONS';
const SET_MODAL = 'SET_MODAL';
const SET_NOTIFICATION_REMINDER = 'SET_NOTIFICATION_REMINDER';
const SET_PERFORMING_ACTION = 'SET_PERFORMING_ACTION';
const SET_NSFW_EMOTES_HIDDEN = 'SET_NSFW_EMOTES_HIDDEN';
const SET_OPENING_PM = 'SET_OPENING_PM';
const SET_SIDEPANE_WIDTH = 'SET_SIDEPANE_WIDTH';
const SET_BLOCK_LIST = 'SET_BLOCK_LIST';
const SET_AUTOCOMPLETE_QUERY = 'SET_AUTOCOMPLETE_QUERY';
const ADD_PENDING_ROOMS = 'ADD_PENDING_ROOMS';
const REMOVE_PENDING_ROOM = 'REMOVE_PENDING_ROOM';
const SET_SHOWING_RAFFLE_ENTRANTS = 'SET_SHOWING_RAFFLE_ENTRANTS';


const chatEmoticonSchema = new schema.Entity('chatEmoticons');
const emoticonListSchema = [chatEmoticonSchema];
const emoticonCategorySchema = new schema.Values(emoticonListSchema);



const addUsedEmote = (usedEmoticons, payload) => {
  if (usedEmoticons.findIndex(emote => emote.plain === payload.plain) === -1) {
    const used = [payload, ...usedEmoticons];
    used.sort((a, b) => (b.uses || 0) - (a.uses || 0));

    return used.slice(0, MAX_RECENTLY_USED);
  }

  const used = usedEmoticons.map((emote) => {
    if (emote.plain === payload.plain) {
      return { ...emote, uses: ((emote.uses || 0) + 1) };
    }

    return emote;
  });

  used.sort((a, b) => (b.uses || 0) - (a.uses || 0));

  return used.slice(0, MAX_RECENTLY_USED);
};

/**
 * The chat reducer
 * @param {ChatState} state
 * @param {any} action
 */
export default function chatReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return produce(state, (draft) => {
        draft.uid = action.payload.uid;
        draft.accessToken = action.payload.accessToken;
      });

    case JOIN_ROOM:
      return produce(state, (draft) => {
        if (draft.rooms.indexOf(action.payload.id) === -1) {
          draft.rooms.push(action.payload.id);
          draft.pendingRooms = draft.pendingRooms.filter(roomName => roomName !== action.payload.name);
        }
      });

    case LEAVE_ROOM:
      return produce(state, (draft) => {
        draft.rooms = draft.rooms.filter(roomId => roomId !== action.payload)
      });

    case CLEAR_ROOMS:
      return produce(state, (draft) => {
        draft.rooms = [];
        draft.activeRoom = null;
      });

    case SET_ACTIVE_ROOM:
      return produce(state, (draft) => {
        draft.activeRoom = action.payload;
      });

    case SET_HIGHLIGHTED_MESSAGE:
      return produce(state, (draft) => {
        draft.highlightedMessage = action.payload;
      });

    case SET_GLOBAL_EMOTICONS:
      return produce(state, (draft) => {
        draft.globalEmoticons = action.payload;
      });

    case SET_EMOTICON_CATEGORIES:
      return produce(state, (draft) => {
        draft.emoticonCategories = action.payload;
      });

    case SET_USED_EMOTES:
      return produce(state, (draft) => {
        draft.usedEmoticons = action.payload;
      });

    case SET_OPTIONS:
      return produce(state, (draft) => {
        draft.options = Object.assign({},  draft.options, action.payload);
      });

    case SET_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = action.payload;
      });

    case SET_NOTIFICATION_REMINDER:
      return produce(state, (draft) => {
        draft.notificationReminder = action.payload;
      });

    case SET_PERFORMING_ACTION:
      return produce(state, (draft) => {
        draft.performingAction = action.payload;
      });

    case SET_NSFW_EMOTES_HIDDEN:
      return produce(state, (draft) => {
        draft.nsfwEmotesHidden = action.payload;
      });

    case SET_OPENING_PM:
      return produce(state, (draft) => {
        draft.openingPM = action.payload;
      });

    case SET_SIDEPANE_WIDTH:
      return produce(state, (draft) => {
        draft.sidePaneWidth = action.payload;
      });

    case SET_BLOCK_LIST:
      return produce(state, (draft) => {
        draft.blockList = action.payload;
      });

    case SET_AUTOCOMPLETE_QUERY:
      return produce(state, (draft) => {
        draft.autocompleteQuery = action.payload;
      });

    case ADD_PENDING_ROOMS:
      return produce(state, (draft) => {
        action.payload.forEach((roomName) => {
          if (draft.pendingRooms.indexOf(roomName) === -1) {
            draft.pendingRooms.push(roomName);
          }
        });
      });
    
    case REMOVE_PENDING_ROOM:
      return produce(state, (draft) => {
        draft.pendingRooms = draft.pendingRooms.filter(roomName => roomName !== action.payload);
      });

    case SET_SHOWING_RAFFLE_ENTRANTS:
      return produce(state, (draft) => {
        draft.showingRaffleEntrants = action.payload;
      });

    default:
      return state;
  }
}

export const isMessageWithMention = (currentUser, message) => {
  // TODO: Fix currentUser being undefined sometimes
  if (!message.text || !currentUser) return;

  const regex = new RegExp(`@(${currentUser.username}|${currentUser.role}|${currentUser.serverRole}|everyone)(\\W|\\s|$)`, 'i');

  return message.text.match(regex);
};

export const setAccessToken = payload => ({ type: SET_ACCESS_TOKEN, payload });

/**
 * Request an access token for the chat server
 * @param {string} username The requested username
 * @returns {AsyncAction}
 */
export function requestAccessToken(username) {
  return async (dispatch, getState, fetch) => {
    const { auth } = getState();
    const authHeaders = auth.uid ? auth : {};
    const response = await fetch(`${config.chat_auth || config.chat_host}/auth`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        ...authHeaders,
      }),
    });

    const json = await response.json();

    if (json.status === 'error') {
      dispatch(handleError(`${json.message} (Code: ${json.code})`, `ErrorCode_${json.code}`));
      return { error: json.message, code: json.code };
    }

    dispatch(setAccessToken({
      accessToken: json.data.token,
      uid: json.data.uid,
    }));

    dispatch(setBlockList(json.data.blockList));

    return {
      token: json.data.token,
      uid: json.data.uid,
      blockList: json.data.blockList,
    };
  };
}

export const joinRoom = (room) => {
  room.joined = true;

  return batchActions([
    patchRoom(room),
    ({
      type: ADD_ENTITIES,
      payload: normalize(room.users, [chatUserSchema]).entities,
    }),
    ({
      type: JOIN_ROOM,
      payload: room,
    }),
    ({
      type: updateHistory,
      payload: {
        messages: room.messages,
        roomId: room.id,
      },
    }),
  ]);
};

export const patchRoom = room => {
  const normalized = normalize(room, chatRoomSchema);

  return ({
    type: ADD_ENTITIES,
    payload: normalized.entities,
  });
};

export const leaveRoom = roomId => ({
  type: LEAVE_ROOM,
  payload: roomId,
});

export const clearRooms = () => (dispatch, getState) => {
  const { rooms } = getState().chat;
  const updatedRooms = rooms.map(id => ({ id, joined: false, opened: false }));
  const normalized = normalize(updatedRooms, [chatRoomSchema]);
  
  dispatch(batchActions([
    {
      type: CLEAR_ROOMS,
    },
    {
      type: ADD_ENTITIES,
      payload: normalized.entities,
    },
  ]));
};

export const setActiveRoom = (roomId, triggeredByUser = false) => (dispatch, getState) => {
  const state = getState();
  const room = state.entities.chatRooms[roomId];

  // skip opening room if the user didn't click it and the room has already been opened before
  if (room) {
    if (!triggeredByUser && room.opened) {
      return;
    }
  }

  dispatch(batchActions([
    // mark room as opened
    ({
      type: ADD_ENTITIES,
      payload: {
        chatRooms: {
          [roomId]: {
            opened: true,
            unread: 0,
          },
        },
      },
    }),
    ({
      type: SET_ACTIVE_ROOM,
      payload: roomId,
    }),
  ]));
};

const addMessageToRoom = (dispatch, room, message) => {
  if (room) {
    const messages = [...room.messages, message];

    const updatedRoom = { ...room, messages };
    const normalized = normalize(updatedRoom, chatRoomSchema);

    dispatch({
      type: ADD_ENTITIES,
      payload: normalized.entities,
    });
  } else {
    dispatch({
      type: ADD_ENTITIES,
      payload: normalize(message, chatMessageSchema).entities,
    });
  }
};

export const addMessage = (roomId, message) => (dispatch, getState) => {
  const state = getState();
  
  const isBlocked = message.user && state.chat.blockList.findIndex(user => user.uid === message.user.userId) !== -1;

  if (isBlocked) return;

  const room = denormalize(roomId, chatRoomSchema, state.entities);

  const { uid, options } = state.chat;

  if (message.component && options[message.component.id] === false) return;

  addMessageToRoom(dispatch, room, message);
};

export const addMessageToRoomName = (roomName, message) => (dispatch, getState) => {
  const state = getState();

  const room = Object.values(state.entities.chatRooms).find(Room => Room.name === roomName);

  if (!room) return;

  const { uid, options } = state.chat;

  if (message.component && options[message.component.id] === false) return;

  addMessageToRoom(dispatch, room, message);
};

export const setEmojiTone = tone => ({
  type: SET_OPTIONS,
  payload: { emojiTone: tone },
});

export const setMentionNotifications = value => ({
  type: SET_OPTIONS,
  payload: { mentionNotifications: value },
});

export const setHighlightedMessage = messageId => ({
  type: SET_HIGHLIGHTED_MESSAGE,
  payload: messageId,
});

export const addUsedEmoticon = emoticon => (dispatch, getState) => {
  const { usedEmoticons } = getState().chat;

  const addedEmoticons = addUsedEmote(usedEmoticons, emoticon);

  dispatch({
    type: SET_USED_EMOTES,
    payload: addedEmoticons,
  });

  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('usedEmoticons', JSON.stringify(addedEmoticons));
  }
};

const setEmoticonCategories = payload => ({
  type: SET_EMOTICON_CATEGORIES,
  payload,
});

const setGlobalEmoticons = payload => ({
  type: SET_GLOBAL_EMOTICONS,
  payload,
});

/**
 * @param {{ [key: string]: string|number|boolean }} payload The updated options to apply
 */
export const setOptions = payload => ({
  type: SET_OPTIONS,
  payload,
});

export const setModal = value => ({
  type: SET_MODAL,
  payload: value,
});

export const setNotificationReminder = value => ({
  type: SET_NOTIFICATION_REMINDER,
  payload: value,
});

/**
 * @param {string?} target target username
 * @param {'kick'|'ban'} [action] action to perform
 */
export const setPerformingAction = (target, action) => ({
  type: SET_PERFORMING_ACTION,
  payload: (target && action) ? ({ target, action }) : null,
});

export const setNsfwEmotesHidden = value => ({
  type: SET_NSFW_EMOTES_HIDDEN,
  payload: value,
});

export const setOpeningPM = uid => ({
  type: SET_OPENING_PM,
  payload: uid,
});

/**
 * sets the last width the chat sidepane had
 * @param {number} width
 */
export const setSidePaneWidth = width => ({
  type: SET_SIDEPANE_WIDTH,
  payload: width,
});

export const setBlockList = payload => ({
  type: SET_BLOCK_LIST,
  payload,
});

export const setAutocompleteQuery = payload => ({
  type: SET_AUTOCOMPLETE_QUERY,
  payload,
});

export const incrementUnread = roomId => (dispatch, getState) => {
  const room = getState().entities.chatRooms[roomId];

  if (room) {
    dispatch(patchRoom({
      id: roomId,
      unread: (room.unread || 0) + 1,
    }));
  }
};

export const markRoomsJoined = (value = true) => (dispatch, getState) => {
  const roomIds = getState().chat.rooms;

  const { entities } = normalize(roomIds.map(id => ({
    id,
    joined: value,
  })), [chatRoomSchema]);

  dispatch({
    type: ADD_ENTITIES,
    payload: entities,
  });
};

/**
 * Async action creator to ((search emotes
 * @param {string} query The search query
 * @param {string} scope The current stream we're in
 */
export const loadAutocompleteSuggestions = (query, scope) => {
  const url = new URL(`${config.api}/emoticons/search/${encodeURIComponent(query)}`);

  if (scope) url.searchParams.set('scope', scope);

  return async (dispatch, getState, fetch) => {
    dispatch(setAutocompleteQuery(query));
    const response = await fetch(url.toString());

    if (response.ok) {
      const json = await response.json();
      const { entities } = normalize(json, emoticonListSchema);

      dispatch(({
        type: ADD_ENTITIES,
        payload: entities,
      }));
    }
  };
};

export const loadGlobalEmoticons = () => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/emoticons/global`);

  if (response.ok) {
    const json = await response.json();

    const { entities, result } = normalize(json, emoticonListSchema);

    dispatch({
      type: ADD_ENTITIES,
      payload: entities,
    });
    dispatch(setGlobalEmoticons(result));
  }
};

export const loadEmoticonCategories = scope => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/emoticons?scope=${encodeURIComponent(scope)}`);

  if (response.ok) {
    const json = await response.json();

    const { entities, result } = normalize(json.data || json, emoticonCategorySchema);

    dispatch({
      type: ADD_ENTITIES,
      payload: entities,
    });

    dispatch(setEmoticonCategories(result));

    if ('meta' in json) {
      dispatch(setNsfwEmotesHidden(json.meta.nsfw_hidden));
    }
  }
};

export const validateEmoticonSet = emoticonSet => async (dispatch, getState, fetch) => {
  const response = await fetch(`${config.api}/emoticons/validate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      emoticons: emoticonSet,
    }),
  });

  if (response.ok) {
    const emoticons = await response.json();

    dispatch({
      type: SET_USED_EMOTES,
      payload: emoticons,
    });
  }
};

export const addPendingRooms = roomNames => (dispatch, getState) => {
  const rooms = getRooms(getState());

  const pendingRooms = roomNames.filter((roomName) => {
    return rooms.findIndex(room => room.name === roomName) === -1;
  });

  dispatch({
    type: ADD_PENDING_ROOMS,
    payload: pendingRooms,
  });
};

export const setShowingRaffleEntrants = createAction(SET_SHOWING_RAFFLE_ENTRANTS);

/**
 * Get the chat access token
 * @param {RootState} state
 * @returns {string|null}
 */
export const getAccessToken = state => state.chat.accessToken;

export const getUser = (state, uid) => state.entities.chatUsers[uid];

// export const getRooms = state => state.chat.rooms.map(id => denormalize(state.entities.chatRooms[id], chatRoomSchema, state.entities));

export const getActiveRoom = state => state.chat.activeRoom;

export const getMessageSender = (state, messageId) => state.entities.chatMessages[messageId].user;

export const getCurrentUid = state => `${state.chat.uid}@${state.chat.activeRoom}`;

export const getCurrentUser = state => state.entities.chatUsers[getCurrentUid(state)];

export const getEmojiTone = state => state.chat.options.emojiTone;

export const getMentionNotifications = state => state.chat.options.mentionNotifications;

export const getHighlightedMessage = state => state.chat.highlightedMessage;

export const getAutocompleteSuggestions = (state) => {
  const queryRegex = new RegExp(state.chat.autocompleteQuery, 'i');
  return Object.values(state.entities.chatEmoticons).filter(emoticon => queryRegex.test(emoticon.plain));
};

export const getGlobalEmoticons = state => denormalize(state.chat.globalEmoticons, emoticonListSchema, state.entities);

export const getEmoticonCategories = state => denormalize(state.chat.emoticonCategories, emoticonCategorySchema, state.entities);

export const getUsedEmoticons = state => state.chat.usedEmoticons;

export const getOptions = state => state.chat.options;

export const getModal = state => state.chat.modalOpen;

export const getNotificationReminder = state => state.chat.notificationReminder && 'Notification' in window && Notification.permission === 'granted';

export const getColor = state => state.chat.options.color;

export const getZoom = state => state.chat.options.zoom;

export const getPerformingAction = state => state.chat.performingAction;

export const getNsfwEmotesHidden = state => state.chat.nsfwEmotesHidden;

export const getOpeningPM = state => state.chat.openingPM;

export const getSidePaneWidth = state => state.chat.sidePaneWidth;

export const getBlockList = state => state.chat.blockList.filter(entry => !!entry);

export const getCurrentRoom = (state) => {
  const roomId = getActiveRoom(state);

  if (!roomId) return null;

  return denormalize(state.entities.chatRooms[roomId], chatRoomSchema, state.entities);
};

export const getPendingRooms = createSelector(
  state => state.chat,
  chat => chat.pendingRooms,
);

export const getRooms = createSelector(
  state => state.chat.rooms,
  state => state.entities.chatRooms,
  state => state.entities.chatMessages,
  state => state.entities.chatUsers,
  (currentRooms, chatRooms, chatMessages, chatUsers) => currentRooms.map(id => denormalize(chatRooms[id], chatRoomSchema, { chatRooms, chatMessages, chatUsers })),
);

export const getShowingRaffleEntrants = createSelector(
  state => state.chat,
  chat => chat.showingRaffleEntrants,
);
