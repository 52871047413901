// State
const INITIAL_STATE = {};

// Actions
export const RESET_FROM_ID = 'piczel/pagination/RESET_FROM_ID';
export const SET_FROM_ID = 'piczel/pagination/SET_FROM_ID';

// Reducer
export default function fromIdReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_FROM_ID:
      return {
        ...state,
        [action.section]: null,
      };
    case SET_FROM_ID:
      return {
        ...state,
        [action.section]: action.fromId,
      };

    default:
      return state;
  }
}

// Action creators
export const resetFromId = section => ({
  type: RESET_FROM_ID,
  section,
});

export const setFromId = (section, fromId) => ({
  type: SET_FROM_ID,
  section,
  fromId,
});

// Selectors
export function getFromId(state, section) {
  if (!(section in state.fromId)) {
    return null;
  }

  return state.fromId[section];
}
