import config from '~/config';
import { sortById } from '~/helpers/data';

import errorHandling from './_errorHandling';
import { setLoading } from './loading';

// State
const INITIAL_STATE = {
  results: {
    byId: {},
    allIds: [],
  },
};

// Loading
export const LOADING_RESULTS = 'piczel/search/LOADING_RESULTS';

// Actions
const RESET_RESULTS = 'piczel/search/RESET_RESULTS';
const UPDATE_RESULTS = 'piczel/search/UPDATE_RESULTS';

// Reducer
export default function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_RESULTS:
      return { ...state, results: { byId: {}, allIds: [] } };
    case UPDATE_RESULTS:
      return { ...state, results: sortById(state.results, action.payload) };
    default: return state;
  }
}

// Action creators
export function resetResults() {
  return { type: RESET_RESULTS };
}

export function updateResults(payload) {
  return { type: UPDATE_RESULTS, payload };
}

export function fetchSearch(options = {}) {
  return async (dispatch) => {
    dispatch(setLoading(LOADING_RESULTS, true));
    if (!options.keepOld) dispatch(resetResults());
    if (!options.category) options.category = 'streams';

    let response = await fetch(`${config.api}/search/${options.category}?q=${encodeURIComponent(options.query)}`);
    response = await errorHandling(dispatch, response);

    response.forEach((item) => {
      item.type = options.category;
    });

    dispatch(updateResults(response));
    dispatch(setLoading(LOADING_RESULTS, false));
  };
}

// Selectors
export function getResultsByType(state, type) {
  return state.allIds.reduce((obj, id) => {
    const item = state.byId[id];
    if (item.type === type) obj.push(item);
    return obj;
  }, []);
}
