import cookie from 'react-cookies';

export const PREFERENCES_KEY = 'preferences_v4';
export const OLD_PREFERENCES_KEY = 'preferences_v3';

/**
 * Represents 1 cookie, that will store a JSON object this way we don't store 1
 * cookie for every single setting
 */
class CookieStorage {
  constructor(name, data = null) {
    this.setData(data || {});
    this.name = name;
  }

  setData(data) {
    this.data = data;

    return this;
  }

  getItem(key, default_val = null) {
    if (key in this.data) {
      return this.data[key];
    }

    return default_val;
  }

  setItem(key, value) {
    this.data[key] = value;

    this.serializeAndPersist();
  }

  removeItem(key) {
    delete this.data[key];

    this.serializeAndPersist();
  }

  destroy() {
    return cookie.remove(this.name);
  }

  serializeAndPersist(data = null) {
    const expires = new Date();
    expires.setDate(expires.getDate() + 30); // Expire in 30 days

    if (data) {
      this.data = data;
    }

    cookie.save(this.name, this.data, {
      path: '/',
      expires,
    });
  }
}

const preferences = new CookieStorage(
  PREFERENCES_KEY,
  cookie.load(PREFERENCES_KEY),
);

const authHeaders = new CookieStorage(
  'authHeaders',
  cookie.load('auth-headers'),
);

function resetCookies() {
  preferences.setData({});
  authHeaders.setData({});
}

export { preferences, authHeaders, resetCookies };
