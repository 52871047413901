import { OVENPLAYER, LL_HLS } from '~/components/StreamPlayer/playerTechs';

/**
 * Current user preferences
 *
 * Storing in a separate reducer since anon users might also have preferences
 */
export const INITIAL_STATE = {
  // player settings
  defaultPlayer: OVENPLAYER,
  defaultVjsTech: LL_HLS,
  performantMultis: true,
  warnPerformantMultis: true,
  warnOfflineMultiMember: true,
  warnWebRTCBFrames: true,
  warnOfflineMultiHost: true,
  autoplay: true,
  enableDVR: false,
};

// actions
export const SET_DEFAULT_PLAYER = 'SET_DEFAULT_PLAYER';
export const SET_VJS_TECH = 'SET_VJS_TECH';
export const SET_PREFERENCE = 'SET_PREFERENCE';

// reducer
export default function preferencesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DEFAULT_PLAYER:
      return {
        ...state,
        defaultPlayer: action.player,
      };

    case SET_VJS_TECH:
      return {
        ...state,
        defaultVjsTech: action.tech,
      };

    case SET_PREFERENCE:
      return {
        ...state,
        [action.key]: action.payload,
      };

    default:
      return state;
  }
}

// action creators
export const setDefaultPlayer = player => ({
  type: SET_DEFAULT_PLAYER,
  player,
});

export const setDefaultVjsTech = tech => ({
  type: SET_VJS_TECH,
  tech,
});

export const setPreference = (key, value) => ({
  type: SET_PREFERENCE,
  key,
  payload: value,
});

export const setPerformantMultis = value => setPreference('performantMultis', value);

export const getDefaultVjsTech = state => state.preferences.defaultVjsTech;

export const getDefaultPlayer = state => state.preferences.defaultPlayer;
