// State
const INITIAL_STATE = [];

// Actions
const ADD_FLASH = 'piczel/flashes/ADD_FLASH';
const REMOVE_FLASH = 'piczel/flashes/REMOVE_FLASH';
const RESET_FLASHES = 'piczel/flashes/RESET_FLASHES';
const HANDLE_ERROR = 'piczel/flashes/HANDLE_ERROR';

// Reducer
export default function flashReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_FLASH:
    case HANDLE_ERROR:
      return [...state, { ...action.payload, id: state.length + 1 }];

    case RESET_FLASHES:
      return INITIAL_STATE;

    case REMOVE_FLASH:
      return state.filter(val => val.id !== action.id);

    default: return state;
  }
}

// Action creators
/**
 * @param {'success'|'error'|'info'} kind
 * @param {string|{id: string, defaultMessage: string}} [message]
 * @param {boolean|number} [autoclose]
 * @param {{to?: string, onClick?: () => void, label: string|{id: string, defaultMessage: string}}[]} [actions]
 */
export function addFlash(kind, message, autoclose = true, actions) {
  return {
    type: ADD_FLASH,
    payload: {
      kind,
      message,
      autoclose: typeof autoclose === 'number' ? autoclose : autoclose && (!actions || !actions.length),
      actions,
    },
  };
}

export function removeFlash(id) {
  return { type: REMOVE_FLASH, id };
}

export function resetFlashes() {
  return { type: RESET_FLASHES };
}

export function handleError(error, messageId) {
  return {
    type: HANDLE_ERROR,
    payload: {
      kind: 'error',
      message: error,
      autoclose: true,
      messageId,
    },
  };
}
