import config from '~/config';
import handleError from './_errorHandling';
import { setLoading } from './loading';
import { sortById } from '~/helpers/data';

const INITIAL_STATE = {
  byId: {},
  allIds: [],
  byUsername: [],
};

export { INITIAL_STATE as INITIAL_STATE_CHATICONS };

// Actions
const RESET_ICONS = 'piczel/admin/RESET_ICONS';
const SET_ICONS = 'piczel/admin/SET_ICONS';

const RESET_ASSIGNED_ICONS = 'piczel/admin/RESET_ASSIGNED_ICONS';
const SET_ASSIGNED_ICONS = 'piczel/admin/SET_ASSIGNED_ICONS';

const REMOVE_ICON = 'piczel/admin/REMOVE_ICON';

// Constants
export const LOADING_CHATICONS = 'piczel/admin/LOADING_CHATICONS';
export const LOADING_ASSIGNED_CHATICONS = 'piczel/admin/LOADING_ASSIGNED_CHATICONS';

// Reducer
export default function adminChatIcons(state, action) {
  switch (action.type) {
    case RESET_ICONS:
      return {
        ...state,
        chatIcons: {
          byId: INITIAL_STATE.byId,
          allIds: INITIAL_STATE.allIds,
        },
      };

    case SET_ICONS:
      return {
        ...state,
        chatIcons: {
          ...state.chatIcons,
          ...sortById(state.chatIcons, action.payload),
        },
      };

    case RESET_ASSIGNED_ICONS:
      return {
        ...state,
        chatIcons: {
          ...state.chatIcons,
          byUsername: INITIAL_STATE.byUsername,
        },
      };

    case SET_ASSIGNED_ICONS:
      return {
        ...state,
        chatIcons: {
          ...state.chatIcons,
          byUsername: action.payload,
        },
      };

    case REMOVE_ICON:
      return {
        ...state,
        chatIcons: {
          ...state.chatIcons,
          allIds: state.chatIcons.allIds.filter(id => action.id !== id),
        },
      };

    default: return state;
  }
}

// Action creators
export function resetIcons() {
  return { type: RESET_ICONS };
}

export function setIcons(payload) {
  return { type: SET_ICONS, payload };
}

export function resetAssignedIcons() {
  return { type: RESET_ASSIGNED_ICONS };
}

export function setAssignedIcons(payload) {
  return { type: SET_ASSIGNED_ICONS, payload };
}

export function removeIcon(id) {
  return { type: REMOVE_ICON, id };
}

export function fetchChatIcons() {
  return function (dispatch, getState, fetch) {
    dispatch(setLoading(LOADING_CHATICONS, true));
    dispatch(resetIcons());

    return fetch(`${config.api}/icons`)
      .then(res => handleError(dispatch, res))
      .then((icons) => {
        dispatch(setIcons(icons));
        dispatch(setLoading(LOADING_CHATICONS, false));
      });
  };
}

export function fetchAssignedChatIcons(opts = {}) {
  return function (dispatch, getState, fetch) {
    if (!opts.silent) dispatch(setLoading(LOADING_ASSIGNED_CHATICONS, true));
    dispatch(resetAssignedIcons());

    return fetch(`${config.api}/icons/assigned`)
      .then(res => handleError(dispatch, res))
      .then((icons) => {
        dispatch(setAssignedIcons(icons));
        dispatch(setLoading(LOADING_ASSIGNED_CHATICONS, false));
      });
  };
}

export function uploadChatIcon(data) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/icons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => handleError(dispatch, res))
      .then((icon) => {
        dispatch(setIcons([icon]));
        dispatch(setLoading(false));
      });
  };
}

export function deleteChatIcon(id) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/icons/${id}`, {
      method: 'DELETE',
    }).then(res => handleError(dispatch, res))
      .then(() => {
        dispatch(removeIcon(id));
      });
  };
}

export function assignChatIcon(data) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/icons/assigned`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => handleError(dispatch, res))
      .then((json) => {
        dispatch(resetAssignedIcons());
        dispatch(setAssignedIcons(json));
      });
  };
}

export function unassignChatIcon(data) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/icons/assigned`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => handleError(dispatch, res))
      .then(() => {
        dispatch(fetchAssignedChatIcons());
      });
  };
}
